import React from "react"
import NastyaMain from "../img/nastya-main-3.png"
import WeInSocialNetwork from "./WeInSocialNetwork";
import ReviewSlider from "./ReviewSlider";
import LiveCourse from "./LiveCourse";
import CareService from "./CareService";
import CourseA0CTA from "./CourseA0CTA";
import Recommendations from "./Recommendations";
import { EmphasiseMontserrat, Beige, PinkGradient } from "./CustomElements";

const Home = (props) => {
  return (
    <div>
        <div className="main-container">
          <div className="name-container">
            <div className="name-container__small">
              <h1>Анастасія Севастьянова</h1>
              <p className="">Вчитель <EmphasiseMontserrat><Beige>німецької мови</Beige></EmphasiseMontserrat>, з яким цікаво навчатись</p>
              {/* <h1><Beige>Анастасія Севастьянова</Beige></h1>
              <p className=""><Beige>Вчитель <EmphasiseMontserrat><PinkGradient>німецької мови</PinkGradient></EmphasiseMontserrat>, з яким цікаво навчатись</Beige></p> */}
              {/* <Link to={"/courses"}><button>Курси</button></Link>
              <Link to={"/aboutme"}><button>Про мене</button></Link> */}
            </div>
            <CourseA0CTA />
          </div>
          <img src={NastyaMain} alt="woman-decoy" />
        </div>
        <WeInSocialNetwork instagram={true} />
        <CareService class={'care-service-relative'}/>
        <Recommendations/>
        <ReviewSlider />
        <CourseA0CTA class={'wholescreen'}/>
        <LiveCourse courseDate="2024-10-15T00:00:00" courseName="Техніки з вивчення іноземних слів на основі 3 форм дієслів" />
    </div>
  )
};

export default Home;
