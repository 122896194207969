import React, { useRef, useState, useEffect } from 'react';
import '../styles/ReviewSlider.css';
import Draggable from './Draggable';
import ReviewImage1 from "../img/image-tyson.png"
import ReviewImage3 from "../img/image-tyson.png"
import ReviewImage4 from "../img/image-tyson.png"
import ReviewImage5 from "../img/image-tyson.png"
import ReviewImage6 from "../img/image-tyson.png"
import ReviewImage7 from "../img/image-tyson.png"
import ReviewImage8 from "../img/image-tyson.png"
import ReviewImage9 from "../img/image-tyson.png"
import ReviewImage10 from "../img/image-tyson.png"
import ReviewImage11 from "../img/image-tyson.png"
import ReviewImage12 from "../img/image-tyson.png"
import ReviewImage13 from "../img/image-tyson.png"
import ReviewImage14 from "../img/image-tyson.png"
import ReviewImage15 from "../img/image-tyson.png"
import ReviewImage16 from "../img/image-tyson.png"
import ReviewImage17 from "../img/image-tyson.png"
import ReviewImage18 from "../img/image-tyson.png"
import ReviewImage19 from "../img/image-tyson.png"
import ReviewImage20 from "../img/image-tyson.png"
import ReviewImage21 from "../img/image-tyson.png"
import ReviewImage22 from "../img/image-tyson.png"
import ReviewImage23 from "../img/image-tyson.png"
import ReviewImage24 from "../img/image-tyson.png"
import ReviewImage25 from "../img/image-tyson.png"
import ReviewImage26 from "../img/image-tyson.png"
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmphasiseMontserrat, Blue } from './CustomElements';

const Reviews = () => {
  const loremText = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore qui consectetur sit facilis beatae, illum, aperiam enim consequuntur veritatis dolor explicabo eligendi recusandae nostrum laudantium doloremque possimus ratione mollitia! Enim. ";
  const timesToRepeat = 3;

  const reviews = [
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage1,
      altText: "Tyson review 2"     
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage3,
      altText: "Tyson review 3"    
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage4,
      altText: "Tyson review 4"     
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage5,
      altText: "Tyson review 5"    
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage6,
      altText: "Tyson review 6" 
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage7,
      altText: "Tyson review 7"    
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage8,
      altText: "Tyson review 8"    
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage9,
      altText: "Tyson review 9"     
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage10,
      altText: "Tyson review 10"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage11,
      altText: "Tyson review 11"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage12,
      altText: "Tyson review 12"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage13,
      altText: "Tyson review 13"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage14,
      altText: "Tyson review 14"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage15,
      altText: "Tyson review 15"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage16,
      altText: "Tyson review 16"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage17,
      altText: "Tyson review 17"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage18,
      altText: "Tyson review 18"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage19,
      altText: "Tyson review 19"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage20,
      altText: "Tyson review 20"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage21,
      altText: "Tyson review 21"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage22,
      altText: "Tyson review 22"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage23,
      altText: "Tyson review 23"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage24,
      altText: "Tyson review 24"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage25,
      altText: "Tyson review 25"
    },
    {
      name: "Tyson",
      text: loremText.repeat(timesToRepeat),
      image: ReviewImage26,
      altText: "Tyson review 26"
    },
  ];

  const extendedReviews = [
    ...reviews,
    ...reviews, // Duplicate to create infinite effect
    ...reviews,
  ];

  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1200);
  const sliderRef = useRef(null);

  // Update isTablet state on window resize
  useEffect(() => {
    const handleResize = () => setIsTablet(window.innerWidth <= 1200);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll to the current index (for slider)
  useEffect(() => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      const scrollWidth = reviewWidth * (extendedReviews.length / 3); // Divide by 3 to avoid jumping
      sliderRef.current.scrollTo({
        left: scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [isTablet]);

  // Navigate to the previous slide
  const scrollLeft = () => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      sliderRef.current.scrollBy({
        left: -reviewWidth,
        behavior: 'smooth',
      });
    }
  };

  // Navigate to the next slide
  const scrollRight = () => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      sliderRef.current.scrollBy({
        left: reviewWidth,
        behavior: 'smooth',
      });
    }
  };

  // Handle scroll events to create an infinite loop
  const handleScroll = () => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      const scrollLeft = sliderRef.current.scrollLeft;
      const scrollWidth = reviewWidth * extendedReviews.length;

      if (scrollLeft >= scrollWidth - sliderRef.current.clientWidth) {
        sliderRef.current.scrollTo({
          left: reviewWidth,
          behavior: 'smooth',
        });
      } else if (scrollLeft <= 0) {
        sliderRef.current.scrollTo({
          left: scrollWidth - reviewWidth * 2,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if(sliderRef.current){
    const container = sliderRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [extendedReviews.length]);

  return (
    <div className="reviews-wrapper">
      <div className="reviews-container">
        <h2 className="reviews-header">Відгуки наших студентів:</h2>
        {isTablet ? (
          <>
            <div className="reviews-slider" ref={sliderRef}>
              {extendedReviews.map((review, index) => (
                <div className="review" key={index}>
                <div className="image-name"> 
                  <img className="review-image" src={review.image} alt={review.altText} draggable={false}/>
                  <h2><EmphasiseMontserrat><Blue>{review.name}</Blue></EmphasiseMontserrat></h2>        
                </div>
                  <p>{review.text}</p>
              </div>
              ))}
            </div>
            <div className="slider-controls">
              <button className="slider-button left" onClick={scrollLeft}>
                <FontAwesomeIcon icon={faArrowLeft} color='dodgerblue' size='2x'/>
              </button>
              <button className="slider-button right" onClick={scrollRight}>
                <FontAwesomeIcon icon={faArrowRight} color='dodgerblue' size='2x'/>
              </button>
            </div>
          </>
        ) : (
          <Draggable rootClass="reviews-slider">
            {extendedReviews.map((review, index) => (
              <div className="review" key={index}>
              <div className="image-name"> 
                <img className="review-image" src={review.image} alt={review.altText} draggable={false}/>
                <h2><EmphasiseMontserrat><Blue>{review.name}</Blue></EmphasiseMontserrat></h2>        
              </div>
                <p>{review.text}</p>
              </div>
            ))}
          </Draggable>
        )}
      </div>
    </div>
  );
};

export default Reviews;
