import React from "react"
import { Link } from "react-router-dom";
import { Blue } from "./CustomElements";
import CoursePhoto1 from '../img/course-photo-1.jpg'

const CourseA0CTA = (props) => {
  return (
    <div>
        <div className={`course-1-container ${props.class}`}>
            <div>
                <div>
                    <h2>
                        <Blue><strong>Тема:</strong></Blue> А0 для чайників
                    </h2>
                    <h2>
                        <Blue><strong>Коли старт:</strong></Blue> 25.10.24
                    </h2>
                    <h2>
                        <Blue><strong>Формат:</strong></Blue> Онлайн
                    </h2>
                    <h2>
                        <Blue><strong>Тривалість:</strong></Blue> 3 місяці
                    </h2>
                    <h2>
                        <Blue><strong>Ціна:</strong></Blue> від 2500грн
                    </h2>
                </div>
                <img src={CoursePhoto1} alt="course-1-cta" className="course-1-cta-img"/>
            </div>
            <Link to={"/course-1"}><button>Детальніше</button></Link>
        </div>
    </div>
  )
};

export default CourseA0CTA;
