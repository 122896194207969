import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import decoyGirl from "../img/face.png"

const WeInSocialNetwork= (props) => {
  return (
    <div>
      {props.instagram && (
        <div className="instagram-line-promo">
          <img src={decoyGirl} alt="anastasia-small" className="instagram-line-promo-image"/>
          <div>
            <h2>Ми в Instagram</h2>
            <a href="https://www.instagram.com/deutsch_mit_tyson/" target="_blank">
              <FontAwesomeIcon size="2x" color="blue" icon={faInstagram}/>
              <h3>Відкрити</h3>
            </a>
          </div>
        </div>
      )}
      {props.telegram && (
      <h2>Telegram</h2>)}
    </div>
  )
};

export default WeInSocialNetwork;
