import React from "react"
import '../styles/Course1.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const AboutMe = (props) => {
  return (
    <div>
        <div className="course-container">
          <div className="course-convertion-container">
            <h2>Придбати курс <FontAwesomeIcon color="dodgerblue" size="2x" icon={faArrowDown}/></h2>
            <a className="course-conversion" href="https://secure.wayforpay.com/verify"><h2>Придбати</h2></a>
          </div>
          <div className="course-description">
            <ol className="course-description-list">
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis rem accusamus magni officia cum earum est quos eum placeat excepturi voluptatem maiores quam perferendis nesciunt possimus, in laborum deleniti sunt.</p></li>
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, expedita quo, magni odit delectus ratione sed nemo aliquam nobis ea, magnam porro? Similique dolorem aliquid veniam. Enim ad cupiditate quod.</p></li>
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, expedita quo, magni odit delectus ratione sed nemo aliquam nobis ea, magnam porro? Similique dolorem aliquid veniam. Enim ad cupiditate quod.</p></li>
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, expedita quo, magni odit delectus ratione sed nemo aliquam nobis ea, magnam porro? Similique dolorem aliquid veniam. Enim ad cupiditate quod.</p></li>
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, expedita quo, magni odit delectus ratione sed nemo aliquam nobis ea, magnam porro? Similique dolorem aliquid veniam. Enim ad cupiditate quod.</p></li>
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, expedita quo, magni odit delectus ratione sed nemo aliquam nobis ea, magnam porro? Similique dolorem aliquid veniam. Enim ad cupiditate quod.</p></li>
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, expedita quo, magni odit delectus ratione sed nemo aliquam nobis ea, magnam porro? Similique dolorem aliquid veniam. Enim ad cupiditate quod.</p></li>
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, expedita quo, magni odit delectus ratione sed nemo aliquam nobis ea, magnam porro? Similique dolorem aliquid veniam. Enim ad cupiditate quod.</p></li>
              <li><p className="course-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, expedita quo, magni odit delectus ratione sed nemo aliquam nobis ea, magnam porro? Similique dolorem aliquid veniam. Enim ad cupiditate quod.</p></li>
            </ol>
          </div>
          <div className="course-convertion-container">
              <h2>Придбати курс <FontAwesomeIcon color="dodgerblue" size="2x" icon={faArrowDown}/></h2>
              <a className="course-conversion" href="https://secure.wayforpay.com/verify"><h2>Придбати</h2></a>
          </div>
        </div>
    </div>
  )
};

export default AboutMe;
