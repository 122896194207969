import React from "react";
import '../styles/recommendations.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmphasiseMontserrat, Blue } from "./CustomElements";
import { faBrain, faListAlt, faUserMd, faThumbsUp, faLightbulb, faComments, faRocket, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

const Recommendations = (props) => {
  const iconsColor = "#ffffff"

  return (
    <div>
      <div className="recommendations">
        <div>
          <h2><EmphasiseMontserrat><Blue>Benefits</Blue></EmphasiseMontserrat></h2>
          <div className="benefits-grid">
            <div>
              <FontAwesomeIcon icon={faBrain} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Сучасні техніки запам'ятовування</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faListAlt} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Структурована програма курсів</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faUserMd} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Психолог для пропрацювання бар’єрів</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faThumbsUp} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Постійна мотивація і підтримка</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faLightbulb} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Пояснюємо складне - просто</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faComments} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Розмовна практика з експертом</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faRocket} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Допомога у швидкому прогресі</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faSyncAlt} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Постійний зворотний зв'язок</h2>
            </div>
          </div>
        </div>
        <div>
          <h2><EmphasiseMontserrat><Blue>Про мене</Blue ></EmphasiseMontserrat></h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing eh2t. Nesciunt, nostrum accusamus! Consectetur animi obcaecati nihil quidem repellendus magnam at beatae rerum veniam. Earum, eveniet! Dicta eum eveniet incidunt fuga temporibus.</p>
        </div>
        <div>
          <h2><EmphasiseMontserrat><Blue>Social proof</Blue></EmphasiseMontserrat></h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing eh2t. Aperiam magnam debitis fugiat error corrupti et! Tempora sed consectetur labore, eaque itaque cum dicta veritatis, reiciendis quidem possimus ea consequuntur doloremque?</p>
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
